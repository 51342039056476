$colors: #2b2d42, #8d99ae, #edf2f4, #ef233c, #d90429;
$max: length($colors);
$dash: 130;
$dash-gap: 40;
$dash-space: $dash * ($max - 1) + $dash-gap * $max;
$time: 21s;
$time-step: $time/$max;

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('./open-sans-v34-latin-800italic.woff2') format('woff2');
}

.text {
  fill: none;
  stroke-width: 1.5;
  stroke-linejoin: round;
  stroke-dasharray: $dash $dash-space;
  stroke-dashoffset: 0;
  -webkit-animation: stroke $time infinite linear;
  animation: stroke $time infinite linear;

  @for $item from 1 through $max {
    &:nth-child(#{$max}n + #{$item}) {
      $color: nth($colors, $item);
      stroke: $color;
      -webkit-animation-delay: -($time-step * $item);
      animation-delay: -($time-step * $item);
    }
  }
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -($dash + $dash-gap) * $max;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -($dash + $dash-gap) * $max;
  }
}

/* Other styles */
html,
body {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(
    -11deg,
    hsl(218, 17%, 100%) 0%,
    hsl(218, 17%, 70%) 90%
  );
  margin: 0;
}

.do-font {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 1px;
}
